import React from 'react';
import { useInView } from 'react-intersection-observer';
import ym from 'react-yandex-metrika';
import { useLocation } from "@reach/router";
import * as PropTypes from "prop-types";
import { Formik, Field, Form }  from "formik";
import * as yup from 'yup';
import axios from 'axios';
import Modal from "react-modal";
import Interpolate from 'react-interpolate-component';

import Button from "../../components/Button";
import CircleWithImage from "../../components/CircleWithImage";
import useLanguage from "../../hooks/useLanguage";
import '../../styles/form/forms/callBackForm.scss';
import iconClose from "../../images/icons/buttons/close.svg";
import iconCloseMobile from "../../images/icons/buttons/close-mobile.svg";
import iconError from "../../images/icons/form/error.svg";
import iconSuccess from "../../images/icons/form/success.svg";
import { createInternalLink } from '../../utils/createInternalLink';

import enMessages from '../../i18n/signUpForm/en.json'
import ruMessages from '../../i18n/signUpForm/ru.json'

const messages = {
  en: enMessages,
  ru: ruMessages,
};

const FORM_SCREEN_DEFAULT = 'companyForm';

const SignUpForm = ({ textSubmit, canBeFixed = "false" }) => {
  Modal.setAppElement("#___gatsby");

  const [language] = useLanguage();

  const { href } = useLocation();

  const { ref, inView } = useInView({
    threshold: 0,
  });

  const [modalIsOpen, setIsOpen] = React.useState(false);

  const [fosData, setFosData] = React.useState({
    screen: FORM_SCREEN_DEFAULT,
  });

  const handleSuccessButtonClick = React.useCallback(() => {
    setIsOpen(false);
    setFosData({ screen: FORM_SCREEN_DEFAULT });
  }, []);

  const submitFOSData = (companyData) => {
    setFosData({ screen: 'process' });

    axios({
      data: companyData,
      headers: { 'content-type': 'application/json' },
      method: 'post',
      url: '/scripts/application/create',
    })
      .then((response) => {
        if (response.data.status === 'success') {
          setFosData({screen: 'success'});
          ym('reachGoal', 'fos_v3_send', { params: { url: href } });
        } else {
          setFosData({screen: 'error'});
        }
      })
      .catch(() => {
        setFosData({screen: 'error'});
      });
  };

  // Форма компании
  const phoneRegExp = /(\s*)?(\+)?([- _():=+]?\d[- _():=+]?){10,14}(\s*)?/;

  const validationSchema = yup.object({
    companyName: yup
      .string(messages[language].form_validation_company_or_entrepreneur)
      .min(2, messages[language].form_validation_company_or_entrepreneur)
      .required(messages[language].form_validation_company_or_entrepreneur),
    contactFullName: yup
      .string(messages[language].form_validation_full_name)
      .min(2, messages[language].form_validation_full_name)
      .required(messages[language].form_validation_full_name),
    email: yup
      .string(messages[language].form_validation_mail)
      .min(2, messages[language].form_validation_mail)
      .required(messages[language].form_validation_mail),
    phone: yup
      .string(messages[language].form_validation_phone)
      .matches(phoneRegExp, messages[language].form_validation_phone_correct)
      .required(messages[language].form_validation_phone),
  });

  const ScreenCompanyForm = () => (
    <Formik
      initialValues={{
        companyName: '',
        confirmation: false,
        contactFullName: '',
        email: '',
        phone: '',
      }}
      onSubmit={(values) => {
        submitFOSData(values);
      }}
      initialStatus="default"
      validationSchema={validationSchema}
    >
      {({ errors, touched, values }) => (
        <div>
          <Form className={`FOSFieldFormBase`} >
            <div className="callBackForm-HeadBlock">
              {messages[language].titleCompanyForm}
            </div>

            <Field
              type="text"
              placeholder={messages[language].companyphone}
              name="phone"
              className="callBackForm-inputText"
            />

            {errors.phone && touched.phone
              ? <div className="callBackForm-ValidationError">{errors.phone}</div>
              : null}

            <Field
              className="callBackForm-inputText"
              name="companyName"
              placeholder={messages[language].companyNameOrEntrepreneur}
              type="text"
            />

            {errors.companyName && touched.companyName
              ? <div className="callBackForm-ValidationError">{errors.companyName}</div>
              : null}

            <Field
              className="callBackForm-inputText"
              name="email"
              placeholder={messages[language].companymail}
              type="text"
            />

            {errors.email && touched.email
              ? <div className="callBackForm-ValidationError">{errors.email}</div>
              : null}

            <Field
              className="callBackForm-inputText"
              name="contactFullName"
              placeholder={messages[language].companyFullName}
              type="text"
            />

            {errors.contactFullName && touched.contactFullName
              ? <div className="callBackForm-ValidationError">{errors.contactFullName}</div>
              : null}

            <div className="callBackForm-agreement formBlockAgreementFos">
              <label>
                <Field type="checkbox" name="confirmation"/>

                  <Interpolate
                    with={{
                      linkToConsentProcessingPD: (
                        <a
                          href={createInternalLink(language, '/consent-processing-pd/')}
                          rel="noreferrer"
                          target="_blank"
                        >
                          {messages[language].confirmationConsentProcessingPD}
                        </a>
                      ),
                      linkToPrivacyPolicy: (
                        <a
                          href={createInternalLink(language, '/dengiru/privacy-policy/')}
                          rel="noreferrer"
                          target="_blank"
                        >
                          {messages[language].confirmationPrivacyPolicy}
                        </a>
                      ),
                    }}
                  >
                    {messages[language].confirmationLabel}
                  </Interpolate>
              </label>
            </div>

            <Button
              disabled={!values.confirmation}
              type="submit"
            >
              {messages[language].submit}
            </Button>
          </Form>
        </div>
      )}
    </Formik>
  );

  const ScreenSuccess = () => (
    <div className="callBackForm-BlockMessage">
      <CircleWithImage color="second" size="medium">
        <img src={iconSuccess} alt="icon" />
      </CircleWithImage>

      <div class="callBackForm-BlockMessage-text">
        {messages[language].form_result_success}
      </div>

      <div class="callBackForm-BlockMessage-button">
        <Button
          click={handleSuccessButtonClick}
          type="submit"
        >
          {messages[language].form_result_success_button}
        </Button>
      </div>
    </div>
  );

  const ScreenError = () => (
    <div className="callBackForm-BlockMessage">
      <CircleWithImage color="first" size="medium">
        <img src={iconError} alt="icon" />
      </CircleWithImage>

      <div class="callBackForm-BlockMessage-text">
        {messages[language].form_result_error}
      </div>

      <div class="callBackForm-BlockMessage-desc">
        {messages[language].form_result_error_desc}
      </div>

      <div class="callBackForm-BlockMessage-button">
        <Button
          type="submit"
          click={handleSuccessButtonClick}
        >
          {messages[language].form_result_error_button}
        </Button>
      </div>
    </div>
  );

  const ScreenProcess = () => (
    <div className="callBackForm-BlockMessage callBackForm-BlockMessageProcess">
      <div class="callBackForm-BlockMessage-text">
        {messages[language].form_result_process}
      </div>
    </div>
  );

  return (
    <div className="form-field">
      <div className="form-field-wrapper" ref={ref}>
        <div className={`button-fos-wrapper ${canBeFixed === "true" ? "button-fos-wrapper-canfixed" : ""} ${inView === false ? "fixedBtn": ""}`}>
          <Button click={() => setIsOpen(true)}>
            {textSubmit ? textSubmit : 'Подключиться'}
          </Button>
        </div>

        <Modal
          className="callBackForm"
          closeTimeoutMS={100}
          isOpen={modalIsOpen}
          onRequestClose={() => setIsOpen(false)}
          overlayClassName="callBackForm-Overlay"
          portalClassName="FOS-callBackForm"
        >
          {fosData.screen === 'companyForm' && <ScreenCompanyForm />}
          {fosData.screen === 'success' && <ScreenSuccess />}
          {fosData.screen === 'error' && <ScreenError />}
          {fosData.screen === 'process' && <ScreenProcess />}

          <a onClick={() => setIsOpen(false)}>
            <img src={iconClose} className="callBackForm-iconClose" />
            <img src={iconCloseMobile} className="callBackForm-iconCloseMobile" />
          </a>
        </Modal>
      </div>
    </div>
  );
};

SignUpForm.propTypes = {
  fosDataStart: PropTypes.object,
  fosDataUser: PropTypes.object,
  textSubmit: PropTypes.string,
};

export default SignUpForm;
