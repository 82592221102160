import React from "react";

import '../styles/buttons/greyButton.scss';

import iconProcess from '../images/icons/buttons/Loader.svg';
import iconDone from '../images/icons/buttons/Done.svg';

const GreyButton = ({ children, className = '', click = () => {}, type = 'text', status = 'default', disabled = false }) => {
    const classes = React.useMemo(() => {
        let cls = 'GreyButton';
        cls += className ? ' ' + className : '';
        cls += ' GreyButton_status_' + status;

        return cls;
    }, [status, className]);

    return (
        <button onClick={() => click()} type={type} className={classes} disabled={disabled}>
            <span className="GreyButton-Text">{children}</span>
            <span className="GreyButton-Loader">
                <img className='loading' src={iconProcess} alt=""/>
            </span>
            <span className="GreyButton-Done">
                <img src={iconDone} alt=""/>
            </span>
        </button>
    )
};

export default GreyButton;
