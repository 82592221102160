import React from 'react';
import GreyButton from "../../components/GreyButton";
import * as Yup from "yup";
import axios from "axios";
import FormikEffect from "../components/FormikEffect";
import {Formik} from "formik";
import useLanguage from "../../hooks/useLanguage";

const SignUpGreyForm = ({submitText = 'Подключиться'}) => {
    const validationSchema = React.useMemo(() => {
        return Yup.object().shape({
            email: Yup.string().email().required()
        })
    }, []);

    const [language] = useLanguage();

    const listLanguage = React.useMemo(() => {
        return {
            errorMessage: {
                ru: 'Введите корректный email',
                en: 'Incorrect email'
            },
            unidentifiedErrorMessage: {
                ru: 'Произошла ошибка',
                en: 'Unidentified error'
            },
            successMessage: {
                ru: 'Спасибо, мы обязательно с вами свяжемся',
                en: 'Thank you, we will be right back'
            }
        }
    }, []);

    const onSubmit = React.useCallback((values, actions) => {
        if (actions.status !== 'process' || actions.status !== 'done') {
            actions.setStatus('process');
            axios({
                method: 'post',
                url: '/ajax.php',
                headers: { 'content-type': 'application/json' },
                data: values
            }).then(response => {
                if (response.data.status === 'success') {
                    actions.setStatus('done');
                    setTimeout(() => {
                        actions.setStatus('default');
                    }, 5000);
                } else {
                    actions.setStatus('error');
                    actions.setFieldError('email', 'error');
                }
            }).catch(reason => {
                actions.setStatus('network-error');
            })
        }
    }, []);

    const onChange = React.useCallback((values, errors, setStatus) => {
        if (Object.keys(errors).length) {
            setStatus('error');
        } else {
            setStatus('default');
        }
    }, []);

    return (
        <Formik
            initialValues={{ email: '' }}
            onSubmit={(values, actions) => {
                onSubmit(values, actions);
            }}
            initialStatus="default"
            validationSchema={validationSchema}
        >
            {props => (
                <form
                    className={`AloneFieldFormSecond${props.status === 'done' ? ' formComplete' : ''}`}
                    onSubmit={props.handleSubmit}
                >
                    <FormikEffect onChange={onChange} />
                    <div className="AloneFieldFormSecond-Fields">
                        <div className={`form-field form-field_underlineInput${props.errors.email ? ' has-error' : ''}`}>
                            <div className="form-field-wrapper">
                                <input
                                    placeholder="Email"
                                    type="text"
                                    onChange={props.handleChange}
                                    onBlur={props.handleBlur}
                                    value={props.values.email}
                                    name="email"
                                />
                            </div>
                            {props.errors.email && props.touched.email && <div className="form-field-error">{listLanguage.errorMessage[language]}</div>}
                            {props.status === 'network-error' && <div className="form-field-error">{listLanguage.unidentifiedErrorMessage[language]}</div>}
                            {props.status === 'done' && <div className="form-field-description">{listLanguage.successMessage[language]}</div>}
                        </div>
                    </div>
                    <div className="AloneFieldFormSecond-Actions">
                        <GreyButton
                            type="submit"
                            className="AloneFieldFormSecond-Action"
                            status={props.status}
                        >
                            {submitText}
                        </GreyButton>
                    </div>
                </form>
            )}
        </Formik>
    );
};

export default SignUpGreyForm;
