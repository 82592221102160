import React from 'react';
import PropTypes from 'prop-types';

import ProductCardsList, {ProductCardsListPropLayout} from "../components/ProductCardsList";
import {ProductCardPropTypes} from "../components/ProductCard";
import SignUpGreyForm from "../forms/instances/SignUpGreyForm";
import TextButton from "../components/TextButton";
import Title, {TitlePropColor} from "../components/typography/Title";
import Block, {BlockPropTypeConfig} from "./Block";
import BlockSection from "./BlockSection";
import SignUpForm from "../forms/instances/SignUpForm";

const InfoCardsBlock = (
    {
        config = {},
        cardsTitle,
        headerStyleNumb,
        cards,
        cardsLayout = 'row',
        cardsTitleColor = 'first',
        cardsGoTo,
        list,
        listTitle,
        listTitleColor,
        listColor = 'first',
        listAdditionalClass = '',
        listPosition = 'beforeCards',
        listGoTo,
        talk,
        submitForm,
        submitFormCaption
    }
    ) => {

    listTitleColor = (listTitleColor ? listTitleColor : listColor)

    const listView = React.useMemo(() => {
        return list && (
            <BlockSection>
                <Title color={listTitleColor} headerStyleNumb={headerStyleNumb} className="Block-Title" innerHTML={listTitle} />
                <ul className={`baseList baseList_theme_${listColor} ${listAdditionalClass}`}>
                    {list.map(listItem => (
                        <li key={listItem} dangerouslySetInnerHTML={{__html: listItem}} />
                    ))}
                </ul>
                {listGoTo && (
                    <div className="Block-Links">
                        <TextButton to={listGoTo.to} href={listGoTo.href} iconId="ArrowRight" styleTheme="goTo">{listGoTo.text}</TextButton>
                    </div>
                )}
            </BlockSection>
        );
    }, [listTitle, list, listColor, listGoTo]);

    return (
        <Block config={config}>
            <div className="container">
                {listPosition === 'beforeCards' && listView}
                {cards && (
                    <BlockSection>
                        {cardsTitle && (
                            <Title color={cardsTitleColor} headerStyleNumb={headerStyleNumb} className="Block-Title" innerHTML={cardsTitle} />
                        )}
                        <ProductCardsList cards={cards} layout={cardsLayout} />
                        {cardsGoTo && (
                            <div className="Block-Links">
                                <TextButton to={cardsGoTo.to} href={cardsGoTo.href} iconId="ArrowRight" styleTheme="goTo">{cardsGoTo.text}</TextButton>
                            </div>
                        )}
                    </BlockSection>
                )}
                {listPosition === 'afterCards' && listView}
                {talk && (
                    <BlockSection moreMargin={true}>
                        <h2 className="header3">Обсудим подробнее?</h2>
                        <div className="Block-Form">
                            <SignUpGreyForm />
                        </div>
                    </BlockSection>
                )}
                {submitForm && (
                    <BlockSection>
                        <div className="Block-Form">
                            <SignUpForm textSubmit={submitFormCaption} />
                        </div>
                    </BlockSection>
                )}
            </div>
        </Block>
    )
};

export const InfoCardsBlockPropTypes = {
    cardsTitle: PropTypes.string,
    cards: PropTypes.arrayOf(PropTypes.shape(ProductCardPropTypes)),
    cardsLayout: ProductCardsListPropLayout,
    cardsTitleColor: TitlePropColor,
    cardsGoTo: PropTypes.shape({
        to: PropTypes.string,
        href: PropTypes.string,
        text: PropTypes.string
    }),
    listTitle: PropTypes.string,
    list: PropTypes.arrayOf(PropTypes.string),
    listPosition: PropTypes.oneOf(['beforeCards', 'afterCards']),
    listColor: PropTypes.oneOf(['first', 'second']),
    listTitleColor: PropTypes.oneOf(['first', 'second', 'black']),
    listGoTo: PropTypes.shape({
        to: PropTypes.string,
        href: PropTypes.string,
        text: PropTypes.string
    }),
    talk: PropTypes.bool,
    haveQuestions: PropTypes.bool,
    config: BlockPropTypeConfig
};

InfoCardsBlock.propTypes = InfoCardsBlockPropTypes;

export default InfoCardsBlock;
