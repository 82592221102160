import React from "react";
import { useFormikContext } from "formik";
import * as PropTypes from "prop-types";

const FormikEffect = ({ onChange }) => {
    const { values, errors, setStatus } = useFormikContext();

    React.useEffect(() => {
        if (typeof onChange === 'function') {
            onChange(values, errors, setStatus);
        }
    }, [values, errors, onChange, setStatus]);

    return null;
};

FormikEffect.propTypes = {
    onChange: PropTypes.func
};

export default FormikEffect;
